import { render } from "./Tutorial.vue?vue&type=template&id=27444e76"
import script from "./Tutorial.ts?vue&type=script&lang=ts"
export * from "./Tutorial.ts?vue&type=script&lang=ts"

import "./tutorial.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "27444e76"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('27444e76', script)) {
    api.reload('27444e76', script)
  }
  
  module.hot.accept("./Tutorial.vue?vue&type=template&id=27444e76", () => {
    api.rerender('27444e76', render)
  })

}

script.__file = "src/views/Tutorial/Tutorial.vue"

export default script